<template>
  <div id="nutrition">

                <h3 class="text-left">Nutrition Facts</h3>
                <p v-if="ingredients !== ''" class="text-left">Ingredients: {{ ingredients }}</p>
    <p class="text-left">{{ description }}</p>
                <p class="text-left">The information below is based on a 100 gram portion.</p>
                <table>
                    <tbody>
                        <tr class="rule1"><td class="text-left large">Calories</td>
                            <td class="text-right large">{{ Math.round(cals*(currentconv.gramWeight/100)) }}</td>
                        </tr>
                        <tr><td></td>
                            <td class="text-right">% <strong>Daily Value</strong></td>
                        </tr>
                        <tr><td class="text-left"><strong>Total Fat</strong> {{ Math.round(fat*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((fat*(currentconv.gramWeight/100))/78*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left indent">Saturated Fat {{ Math.round(satfat*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((satfat*(currentconv.gramWeight/100))/20*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left indent">Trans Fat {{ Math.round(trans*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"></td>
                        </tr>
                        <tr><td class="text-left"><strong>Cholesterol</strong> {{ Math.round(chol*(currentconv.gramWeight/100)) }}mg</td>
                            <td class="text-right"><strong>{{ Math.round((chol*(currentconv.gramWeight/100))/300*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left"><strong>Sodium</strong> {{ Math.round(salt*(currentconv.gramWeight/100)) }}mg</td>
                            <td class="text-right"><strong>{{ Math.round((salt*(currentconv.gramWeight/100))/2300*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left"><strong>Total Carbohydrate</strong> {{ Math.round(carb*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((carb*(currentconv.gramWeight/100))/275*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left indent">Dietary Fiber {{ Math.round(fiber*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((fiber*(currentconv.gramWeight/100))/28*100) }}</strong>%</td>
                        </tr>
                        <tr><td class="text-left indent">Total Sugars {{ Math.round(sugar*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((sugar*(currentconv.gramWeight/100))/50*100) }}</strong>%</td>
                        </tr>
                        <tr class="rule2"><td class="text-left"><strong>Protein</strong> {{ Math.round(protein*(currentconv.gramWeight/100)) }}g</td>
                            <td class="text-right"><strong>{{ Math.round((protein*(currentconv.gramWeight/100))/50*100) }}</strong>%</td>
                        </tr>
                    <tr>
                      <th class="text-left">Vitamin / Mineral</th>
                      <th class="text-right"></th>
                    </tr>
                    <tr v-for="item in vitaminerals" :key="item.id">
                      <td class="text-left" v-if="item.amount > 0">{{ item.name }} {{ Math.round(item.amount*(currentconv.gramWeight/100)) }}{{ item.units }}</td>
                      <td class="text-right" v-if="item.amount > 0"><strong>{{ Math.round((item.amount*(currentconv.gramWeight/100))/item.dv*100) }}</strong>%</td>
                    </tr>
                  </tbody>
              </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'tc-nutrition',
  props: { food: { type: String, default: '168577' } },
  data: () => ({
    searchTerm: '',
    resultList: [],
    nutrition: [],
    currentconv: { id: 1, gramWeight: 100, modifier: "100 grams" },
    ingredients: '',
    description: '',
    showDetails: false,
    vitamins: [],
    portion: "100 grams",
    vitaminerals: [
        { id: 1, name: "Biotin", dv: 300, amount: 0, units: '' },
        { id: 417, name: "Folic Acid", dv: 400, amount: 0, units: '' },
        { id: 406, name: "Niacin", dv: 20, amount: 0, units: '' },
        { id: 410, name: "Pantothetic Acid", dv: 10, amount: 0, units: '' },
        { id: 405, name: "Riboflavin", dv: 1.7, amount: 0, units: '' },
        { id: 404, name: "Thiamin", dv: 1.5, amount: 0, units: '' },
        { id: 318, name: "Vitamin A", dv: 5000, amount: 0, units: '' },
        { id: 415, name: "Vitamin B-6", dv: 2, amount: 0, units: '' },
        { id: 418, name: "Vitamin B-12", dv: 6, amount: 0, units: '' },
        { id: 401, name: "Vitamin C", dv: 60, amount: 0, units: '' },
        { id: 324, name: "Vitamin D", dv: 400, amount: 0, units: '' },
        { id: 323, name: "Vitamin E", dv: 30, amount: 0, units: '' },
        { id: 430, name: "Vitamin K", dv: 80, amount: 0, units: '' },
        { id: 301, name: "Calcium", dv: 1000, amount: 0, units: '' },
        { id: 15, name: "Chloride", dv: 3400, amount: 0, units: '' },
        { id: 16, name: "Chromium", dv: 120, amount: 0, units: '' },
        { id: 312, name: "Copper", dv: 2, amount: 0, units: '' },
        { id: 17, name: "Iodine", dv: 150, amount: 0, units: '' },
        { id: 303, name: "Iron", dv: 18, amount: 0, units: '' },
        { id: 304, name: "Magnesium", dv: 400, amount: 0, units: '' },
        { id: 315, name: "Manganese", dv: 2, amount: 0, units: '' },
        { id: 22, name: "Molybdenum", dv: 75, amount: 0, units: '' },
        { id: 305, name: "Phosporus", dv: 1000, amount: 0, units: '' },
        { id: 306, name: "Potassium", dv: 3500, amount: 0, units: '' },
        { id: 317, name: "Selenium", dv: 70, amount: 0, units: '' },
        { id: 307, name: "Sodium", dv: 2400, amount: 0, units: '' },
        { id: 309, name: "Zinc", dv: 15, amount: 0, units: '' }
    ],
    protein: 0,
    fat: 0,
    carb: 0,
    ash: 0,
    cals: 0,
    water: 0,
    sugar: 0,
    fiber: 0,
    chol: 0,
    trans: 0,
    satfat: 0,
    monofat: 0,
    polyfat: 0,
    salt: 0
  }),
  created: function () {
      const nutrients = [
            { id: 203, field: 'protein' },
            { id: 204, field: 'fat' },
            { id: 205, field: 'carb' },
            { id: 207, field: 'ash' },
            { id: 208, field: 'cals' },
            { id: 255, field: 'water' },
            { id: 269, field: 'sugar' },
            { id: 291, field: 'fiber' },
            { id: 307, field: 'salt' },
            { id: 601, field: 'chol' },
            { id: 605, field: 'trans' },
            { id: 606, field: 'satfat' },
            { id: 645, field: 'monofat' },
            { id: 646, field: 'polyfat' }
        ]
      this.ingredients = ''
      axios.get('https://api.nal.usda.gov/fdc/v1/food/' + this.food + '?api_key=uHsQKXy2poBcjUYiBWVxxXyaJIc5CoQGUvLPi3dU')
        .then(response => {
          // eslint-disable-next-line
          // console.log(response.data)
          this.description = response.data.description
          this.nutrition = response.data.foodNutrients
          for (var i = 0; i < this.nutrition.length; i++) {
              const tmpVal = nutrients.find(nutrient => nutrient.id == this.nutrition[i].nutrient.number)
              if (tmpVal) {
                  this[tmpVal.field] = this.nutrition[i].amount
              } else {
                  // Handle vitamins and minerals
                  if (this.nutrition[i].amount > 0) {
                      const tmpVit = this.vitaminerals.find(nutrient => nutrient.id == this.nutrition[i].nutrient.number)
                      if (tmpVit) {
                          tmpVit.amount = this.nutrition[i].amount
                          tmpVit.units = this.nutrition[i].nutrient.unitName
                      }
                  }
                  //this.vitamins.push(this.nutrition[i])
              }
          }
          if (response.data.ingredients) { this.ingredients = response.data.ingredients }
          // eslint-disable-next-line
          //console.log(response.data.foodPortions)
      })
  }
}
</script>

<style>
    #nutrition { width: 100%; }
    table { width: 100%; border: 1px solid black; border-collapse: collapse; border-spacing: .5em; }
    td, th { border-bottom: 1px solid black; padding: .5em; font-family: Montserrat, Arial, sans-serif; }
    .rule1 { border-bottom: 5px solid black; }
    .rule2 { border-bottom: 8px solid black; }
    .text-left { text-align: left; }
    .text-right { text-align: right; }
    .indent { padding-left: 1em; }
    .large { font-size: 1.25em; font-weight: bold; }
</style>